import React from "react";
import "./../styles/global.css";
import IndexPage from "./index";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import ParticlesComponent from "./../components/particles";

function App(props) {
  return (
    <Router>
      <div className="App">
        <ParticlesComponent id="particles" />
      </div>
      <div>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
