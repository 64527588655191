import React from "react";
import Meta from "./../components/Meta";
import Hero from "./../components/Hero";
import { reportEvent } from '../util/analytics';

function IndexPage(props) {
  reportEvent('Page View', props);

  return (
    <>
      <Meta />
      <Hero
        bgColor="primary"
        size="large"
        bgImage="/earth.jpg"
        bgImageOpacity={0.3}
        title="OBZURVR"
        subtitle="Something awesome is brewing!"
        textColor="text-white"
        titleColor="#32CD32" // Custom title color
        subtitleColor="#32CD32" // Custom subtitle color
        titleFont="Avenir, serif" // Custom title font
        subtitleFont="Merriweather, sans-serif" // Custom subtitle font
      />
    </>
  );
}

export default IndexPage;
