import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function Hero(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="flex items-center justify-center min-h-screen"
    >
    <Container>
      <Box textAlign="center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          titleColor={props.titleColor}
          subtitleColor={props.subtitleColor}
          titleFont={props.titleFont}
          subtitleFont={props.subtitleFont}
        />
      </Box>
    </Container>
  </Section>
  );
}

export default Hero;
