import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function SectionHeader(props) {
  return (
    <Box mb={4}>
      <Typography
        variant="h2"
        component="h1"
        style={{
          color: props.titleColor || "white",
          fontFamily: props.titleFont || "Arial, sans-serif",
        }}
      >
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography
          variant="h5"
          component="h2"
          style={{
            color: props.subtitleColor || "white",
            fontFamily: props.subtitleFont || "Arial, sans-serif",
          }}
        >
          {props.subtitle}
        </Typography>
      )}
    </Box>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  titleFont: PropTypes.string,
  subtitleFont: PropTypes.string,
};

export default SectionHeader;
