import Analytics from "analytics";
import googleAnalyticsPlugin from "@analytics/google-analytics";
import amplitudePlugin from "@analytics/amplitude";
import { history } from "./router.js";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
  debug: process.env.NODE_ENV !== "production",
  plugins: [
    // Instructions: https://divjoy.com/docs/amplitude
    googleAnalyticsPlugin({
      measurementIds: [process.env.REACT_APP_GA_MEASUREMENT_ID],
    }),
    amplitudePlugin({
      apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
      // See options at https://amplitude.github.io/Amplitude-JavaScript/Options/
      options: {
        trackingOptions: {
          ip_address: false,
        },
      },
    }),
  ],
});

export const getPathForAnalytics = (props) => {
  const Path = props?.location?.pathname || ``;
  const Variant = Path.split(`/`)[1] || ``;
  return { Path, Variant: Variant || 'none' };
};

export const reportEvent = (event = ``, payload = {}) => {
  try {
    switch (event) {
      case 'Page View':
      case 'Contact Form Send':
        console.log('Analytics event: ', event, getPathForAnalytics(payload));
        analytics.track(event, getPathForAnalytics(payload));
        break;
      default:
        // default code block
        break;
    }
  } catch (e) {
    console.log('Error send analytics');
  }
};

// Track initial pageview
if (typeof window !== "undefined") {
  analytics.page();
}

// Track pageview on route change
history.listen(() => {
  analytics.page();
});

export default analytics;
